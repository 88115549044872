<template>
  <div v-if="loading" class="wrapper-lg">
    <sl-loading content="" />
  </div>
  <div class="cliente-fatura" v-else>
    <div class="f-left">
      <div class="fatura-nav">
        <router-link :to="{name: 'suporte.cliente.faturas'}">Voltar para faturas</router-link>
      </div>
      <div class="fatura" style="min-height: 0" v-if="$route.query.sucessoPagamento">
        <div class="success-checkmark">
          <div class="check-icon">
            <span class="icon-line line-tip"></span>
            <span class="icon-line line-long"></span>
            <div class="icon-circle"></div>
            <div class="icon-fix"></div>
          </div>
        </div>
        <div style="text-transform: none" class="text-center m-b-lg m-t-n">
          Obrigado pelo seu pagamento.
        </div>
      </div>
      <div class="fatura">
        <div v-if="fatura.pago" class="stamp is-approved">
          FATURA PAGA
        </div>
        <e-row mr>
          <e-col>
            <erp-s-field label="ID Fatura:">
              {{ String(fatura.id).padStart(8, '0') }}
            </erp-s-field>
          </e-col>
          <e-col>
            <erp-s-field label="Código de Segurança:">
              {{fatura.transacaoId}}
            </erp-s-field>
          </e-col>
          <e-col class="text-right">
            <erp-s-field label="Valor:" view="tr">
              <strong>R${{fatura.valor|moeda}}</strong>
            </erp-s-field>
          </e-col>
        </e-row>
        <e-row mr>
          <e-col>
            <erp-s-field label="Data Vencimento:">
              {{fatura.dataVencimento|formatDate('dd/MM/yyyy')}}
            </erp-s-field>
          </e-col>
          <e-col>
            <erp-s-field label="Data Competência:">
              {{fatura.dataCompetencia|formatDate('dd/MM/yyyy')}}
            </erp-s-field>
          </e-col>
          <e-col class="text-right">
            <erp-s-field label="Data de Pagamento:" view="tr">
              <span v-if="fatura.pago" class="text-green font-bold">{{fatura.dataPagamento|formatDate('dd/MM/yyyy')}}</span>
              <span v-else></span>
            </erp-s-field>
          </e-col>
        </e-row>
        <e-row mr>
          <e-col>
            <erp-s-field label="Juros e Multa:">
              {{fatura.jurosMulta|moeda}}
            </erp-s-field>
          </e-col>
          <e-col>
            <erp-s-field label="Descontos:">
              {{fatura.descontos|moeda}}
            </erp-s-field>
          </e-col>
          <e-col class="text-right">
            <erp-s-field label="Valor Pago:" view="tr">
              R${{fatura.valorPago|moeda}}
            </erp-s-field>
          </e-col>
        </e-row>
        <e-row mr>
          <e-col>
            <erp-s-field label="Forma de Pagamento:">
              <div v-if="!fatura.extra || !fatura.extra.pagamento || !fatura.extra.pagamento.method">-</div>
              <div v-else-if="fatura.extra.pagamento.method === 'cartao'">Cartão de Crédito</div>
              <div v-else-if="fatura.extra.pagamento.method === 'boleto'">Boleto Bancário</div>
              <div v-else-if="fatura.extra.pagamento.method === 'pix'">Pix</div>
              <div v-else>-</div>
            </erp-s-field>
          </e-col>
<!--          <e-col>
            <erp-s-field label="Descontos:">
              {{fatura.descontos|moeda}}
            </erp-s-field>
          </e-col>
          <e-col class="text-right">
            <erp-s-field label="Valor Pago:" view="tr">
              R${{fatura.valorPago|moeda}}
            </erp-s-field>
          </e-col>-->
        </e-row>
        <e-row mr>
          <e-col>
            <erp-s-field label="Sacado:">
              <strong>{{fatura.pessoa.name}}</strong>
            </erp-s-field>
          </e-col>
          <e-col class="text-right">
            <erp-s-field label="Documento:" view="tr">
              <strong>{{fatura.pessoa.document|formataCpfCnpj2}}</strong>
            </erp-s-field>
          </e-col>
        </e-row>
        <e-row mr>
          <e-col>
            <erp-s-field label="Descrição do serviço:">
              <strong>{{fatura.descricao}}</strong>
            </erp-s-field>
          </e-col>
        </e-row>
        <template v-if="fatura.detalhes && fatura.detalhes.length">
          <e-row>
            <e-col style="border-top: #CCCCCC 1px dotted; padding-top: 10px">
              <strong>+Detalhes da fatura+</strong>
            </e-col>
          </e-row>
          <e-row v-for="d in fatura.detalhes" :key="'d-' + d.id" mr>
            <e-col>
              <erp-s-field label="Descrição:">
                {{d.descricao}}
              </erp-s-field>
            </e-col>
            <e-col style="max-width: 80px">
              <erp-s-field label="Quantidade:">
                <strong>{{d.quantidade || 1}}</strong>
              </erp-s-field>
            </e-col>
            <e-col style="max-width: 80px" class="text-right">
              <erp-s-field label="Valor:" view="tr">
                <strong>{{d.valor|moeda}}</strong>
              </erp-s-field>
            </e-col>
          </e-row>
          <e-row class="m-t-n">
            <e-col style="border-bottom: #CCCCCC 1px dotted; padding-bottom: 10px">
            </e-col>
          </e-row>
        </template>
        <div v-if="!fatura.pago && !fatura.cancelado" class="text-center m-t-lg">
          <u-btn @click="pagar" class="sl-btn" color="green" label="Pagar agora" no-caps />
        </div>
      </div>
      <div class="fatura-opts">
        <a @click="downloadInvoice">
          <span v-if="fatura.pago">Baixar Recibo</span>
          <span v-else>Baixar Fatura</span>
        </a>
      </div>
    </div>
  </div>
</template>

<script>

import {invoice, downloadInvoice} from "@/domain/business/services"
import SlLoading from "components/layout/components/Loading.vue"
import ERow from "components/layout/components/Row.vue"
import ECol from "components/layout/components/Col.vue"
import ErpSField from "@/plugins/uloc-erp/components/form/SimpleField"
import {donwloadFile} from "@/utils/downloadFile";

export default {
  name: "ClienteFatura",
  data () {
    return {
      loading: true,
      fatura: null,
      methods: null
    }
  },
  computed: {
  },
  components: {
    ErpSField,
    ECol,
    ERow,
    SlLoading
  },
  mounted() {
    this.load(this.$route.params.id)
  },
  methods: {
    load (id) {
      this.loading = true
      invoice(id)
          .then(response => {
            this.fatura = response.data.invoice
            this.methods = response.data.methods
            this.loading = false
          })
          .catch(error => {
            this.alertApiError(error)
            // this.loading = false
          })
    },
    downloadInvoice () {
      downloadInvoice(this.fatura.id)
          .then((response) => {
            donwloadFile(response)
          })
          .catch(error => {
            this.alertApiError(error)
          })
    },
    pagar () {
      this.$router.push({
        name: 'suporte.cliente.faturas.pagarFatura',
        params: {id: this.fatura.id}
      })
    }
  }
}
</script>
